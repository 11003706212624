// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
// import "./Navbar.css";
// import logo from "../Images/source.svg"; // Replace with the correct path to your logo image

// const Navbar = () => {
//   // State to manage the toggle for the mobile menu
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   // Create a navigate function using useNavigate
//   const navigate = useNavigate();

//   // Function to toggle the menu
//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   // Function to handle navigation when logo or CodeXodus text is clicked
//   const handleNavigateHome = () => {
//     navigate("/"); // Navigate to home route
//   };

//   return (
//     <nav className="navbar">
//       <div className="navbar__logo-container" onClick={handleNavigateHome}>
//         <img src={logo} alt="CodeXodus Logo" className="navbar__logo" />
//         <span className="navbar__brand">
//           <span className="navbar__brand--highlight">COD</span>E
//           <span className="Navbar_x_text">X</span>ODUS
//         </span>
//       </div>

//       {/* Hamburger Icon */}
//       <div className="hamburger" onClick={toggleMenu}>
//         <div></div>
//         <div></div>
//         <div></div>
//       </div>

//       {/* Menu - shown or hidden based on state */}
//       <ul
//         className={`navbar__menu ${isMenuOpen ? "navbar__menu--active" : ""}`}
//       >
//         <li className="navbar__item">Colleges & University</li>
//         <li className="navbar__item">Student</li>
//         <li className="navbar__item">Coaching Institutions</li>
//       </ul>

//       <div className="navbar__buttons">
//         <button className="navbar__button navbar__button--secondary">
//           Sign In
//         </button>
//         <button className="navbar__button navbar__button--primary">
//           Sign Up
//         </button>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import "./Navbar.css";
import logo from "../Images/source.svg"; // Replace with the correct path to your logo image

const Navbar = () => {
  // State to manage the toggle for the mobile menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Create a navigate function using useNavigate
  const navigate = useNavigate();

  // Function to toggle the menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to handle navigation when logo or CodeXodus text is clicked
  const handleNavigateHome = () => {
    navigate("/"); // Navigate to home route
  };

  // Function to navigate to contact-us page
  const handleGetInTouch = () => {
    navigate("/contact-us"); // Navigate to Contact Us page
  };

  // Function to initiate a phone call
  const handleCallUs = () => {
    window.location.href = "tel:+919894079619"; // Initiate a phone call
  };

  return (
    <nav className="navbar">
      <div className="navbar__logo-container" onClick={handleNavigateHome}>
        <img src={logo} alt="CodeXodus Logo" className="navbar__logo" />
        <span className="navbar__brand">
          <span className="navbar__brand--highlight">COD</span>E
          <span className="Navbar_x_text">X</span>ODUS
        </span>
      </div>

      {/* Hamburger Icon */}
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Menu - shown or hidden based on state */}
      <ul
        className={`navbar__menu ${isMenuOpen ? "navbar__menu--active" : ""}`}
      >
        <li className="navbar__item">Colleges & University</li>
        <li className="navbar__item">Student</li>
        <li className="navbar__item">Coaching Institutions</li>
      </ul>

      <div className="navbar__buttons">
        <button
          className="navbar__button navbar__button--secondary"
          onClick={handleGetInTouch}
        >
          Get in Touch
        </button>
        <button
          className="navbar__button navbar__button--primary"
          onClick={handleCallUs}
        >
          Call Us
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
