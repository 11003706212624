import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./CallToAction.css";
import Modal from "react-modal";

const CallToAction = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false); // Modal state
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  }); // Form data state
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Only animate once on scroll
    });
  }, []);

  // Use navigate for routing
  const navigate = useNavigate();

  // Handle navigation to Contact Us page
  const handleTalkToSalesClick = () => {
    navigate("/contact-us"); // Navigate to the Contact Us page
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle modal form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    alert("Free trial requested!");
    setModalIsOpen(false); // Close the modal after submission

    // Redirect to the trial website in a new window
    window.open("https://trail.codexodus.com", "_blank"); // Open in a new tab/window
  };

  return (
    <div className="ctasection">
      <section className="cta">
        <div className="cta__content" data-aos="fade-up">
          <h2 className="cta__text">
            Create your account today <br />
            and get started for free!
          </h2>
          <div className="cta__buttons">
            <button
              className="cta__button cta__button--primary"
              data-aos="fade-right"
              onClick={() => setModalIsOpen(true)}
            >
              Get started
            </button>
            <button
              className="cta__button cta__button--secondary"
              data-aos="fade-left"
              onClick={handleTalkToSalesClick} // Attach the click handler to navigate
            >
              Talk to sales
            </button>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Free Trial Modal"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <h2>Start Your Free Trial</h2>
          <form className="modal-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="button-group">
              <button type="submit">Submit</button>
              <button type="button" onClick={() => setModalIsOpen(false)}>
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      </section>
    </div>
  );
};

export default CallToAction;
