import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./Common/Footer";
import Navbar from "./Common/Navbar";
import ContactUs from "./Pages/ContactUs";
import whatsappIcon from "./Images/whatsapp.svg"; // Adjust this path
import HomePageLayout from "./Pages/Layout";
import Terms from "./Pages/TermsAndConditions";
import Courser from "./Courser";

// App component with routing
function App() {
  return (
    <Router>
      <Navbar />
      <Courser/>
      <Routes>
        {/* Route for HomePage layout */}
        <Route path="/" element={<HomePageLayout />} />

        {/* Route for Contact Us page */}
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
      </Routes>
      <a
        href="https://wa.me/919894079619" // Correct format: no spaces or special characters
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappIcon} alt="WhatsApp" />
      </a>
      <Footer />
    </Router>
  );
}

export default App;
