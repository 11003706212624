import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import Modal from "react-modal";
import "aos/dist/aos.css";
import "./CodeXodus.css";
import rightSideImage from "../Images/character.svg"; // Adjust the path accordingly

// Set app element for accessibility
Modal.setAppElement("#root");

const CodeXodus = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false); // Modal state
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  }); // Form data state

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: true, // If true, animation will happen only once
    });
  }, []);

  // Use navigate for routing
  const navigate = useNavigate();

  // Handle "Get In Touch" click
  const handleGetInTouchClick = () => {
    navigate("/contact-us");
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle modal form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    alert("Free trial requested!");
    setModalIsOpen(false); // Close the modal after submission

    // Redirect to the trial website in a new window
    window.open("https://trail.codexodus.com", "_blank"); // Open in a new tab/window
  };

  return (
    <div className="codexodus">
      <div className="codexodus__content" data-aos="fade-right">
        <h1 className="codexodus__title">
          <span className="codexodus__title--highlight">
            Master Coding Skills with
          </span>
        </h1>
        <h2 className="codexodus__brand">&lt;codeXodus/&gt;</h2>
        <p>
          Your ultimate destination for mastering{" "}
          <strong>programming skills</strong>, <strong>data structures</strong>,
          and <strong>algorithms</strong>.
          <br />
          <br />
          Whether you're a <strong>beginner</strong> or an{" "}
          <strong>advanced learner</strong>, CodeXodus offers an{" "}
          <strong>AI-driven</strong>, interactive learning experience tailored
          just for you.
        </p>
        <div className="codexodus__buttons">
          <button
            className="codexodus__button codexodus__button--primary"
            onClick={() => setModalIsOpen(true)} // Open the modal
          >
            Start Your Free Trial
          </button>
          <button
            className="codexodus__button codexodus__button--secondary"
            onClick={handleGetInTouchClick} // Navigate to contact-us page
          >
            Get In Touch
          </button>
        </div>
      </div>
      <div className="codexodus__image-container" data-aos="fade-left">
        <img
          src={rightSideImage}
          alt="CodeXodus Benefits"
          className="codexodus__character-image"
        />
      </div>

      {/* Modal for Free Trial */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Free Trial Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Start Your Free Trial</h2>
        <form className="modal-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="button-group">
            <button type="submit">Submit</button>
            <button type="button" onClick={() => setModalIsOpen(false)}>
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CodeXodus;
